import React from 'react';

import { WorkspaceType } from '@bangle.io/constants';
import type { ListBoxOptionComponentType } from '@bangle.io/ui-components';
import {
  CheckIcon,
  Dialog,
  Item,
  ListBox,
  mergeProps,
  useFocusRing,
  useListState,
  useOption,
} from '@bangle.io/ui-components';
import { cx } from '@bangle.io/utils';

import { defaultStorage, disabledStorageType } from './common';

export function PickWorkspaceType({
  onDismiss,
  onSelect,
  hasGithub,
  hasPrivateFs,
}: {
  onSelect: (type: WorkspaceType) => void;
  onDismiss: () => void;
  hasGithub?: boolean;
  hasPrivateFs: boolean;
}) {
  const [selectedKey, updateSelectedKey] =
    React.useState<WorkspaceType>(defaultStorage);

  const state = useListState({
    children: [
      // hasGithub ? (
      //   <Item key={WorkspaceType.Github} textValue="github storage">
      //     <div>
      //       <div>
      //         <span className="font-bold">GitHub Storage</span>
      //       </div>
      //       <div>
      //         <span className="text-sm text-justify">
      //           Allows you to sync your notes with a GitHub repository of your
      //           choice. It will keep your notes synced across multiple devices.
      //         </span>
      //       </div>
      //     </div>
      //   </Item>
      // ) : null,

      <Item
        key={hasPrivateFs ? WorkspaceType.PrivateFS : WorkspaceType.Browser}
        textValue="browser storage"
      >
        <div>
          <div>
            <span className="font-bold">Navegador</span>
            <span> (Recomendado)</span>
          </div>
          <div>
            <span className="text-sm text-justify">
              Armazena notas no armazenamento do seu navegador. Uma boa opção se
              você quiser experimentar o Vigogh Nota. No entanto, você pode
              perder suas anotações se limpar o armazenamento do navegador.
            </span>
          </div>
        </div>
      </Item>,

      <Item
        aria-label="local file storage"
        key={WorkspaceType.NativeFS}
        textValue="local file storage"
      >
        <div>
          <div>
            <span className="font-bold">Armazenamento local</span>
            {disabledStorageType.includes(WorkspaceType.NativeFS) && (
              <span> (Não disponível)</span>
            )}
          </div>
          <div>
            <span className="text-sm text-justify">
              Esta opção permite salvar notas diretamente em uma pasta do seu
              computador de sua escolha, fornecendo o controle total das suas
              notas a você, além de uma maneira fácil de fazer backup.
            </span>
          </div>
        </div>
      </Item>,
    ].filter((r): r is React.ReactElement => Boolean(r)),
    selectionMode: 'single',
    disabledKeys: disabledStorageType,
    selectedKeys: [selectedKey],

    onSelectionChange(keys) {
      // keys cannot be all since single
      if (typeof keys !== 'string') {
        const key = [...keys][0];

        if (key) {
          updateSelectedKey(key as WorkspaceType);
        }
      }
    },
  });

  return (
    <Dialog
      footer={
        <></>
        // <ExternalLink
        //   text="Vigogh Nota é distribuído pela Cloudflare e fica inteiramente em seu navegador."
        //   href="https://bangle.io/privacy"
        // />
      }
      isDismissable
      headingTitle="Escolha o tipo de armazenamento"
      onDismiss={onDismiss}
      size="md"
      primaryButtonConfig={{
        text: 'Próximo',
        onPress: () => {
          onSelect(selectedKey);
        },
      }}
    >
      <ListBox
        label="Escolha um tipo de armazenamento"
        state={state}
        optionComponent={Option}
        className="B-core-extension_pick-workspace-storage"
      />
    </Dialog>
  );
}

const Option: ListBoxOptionComponentType = ({ item, state }) => {
  // Get props for the option element
  let ref = React.useRef<HTMLLIElement>(null);

  let { optionProps, isSelected, isDisabled, isFocused } = useOption(
    { key: item.key },
    state,
    ref,
  );

  // Determine whether we should show a keyboard
  // focus ring for accessibility
  let { isFocusVisible, focusProps } = useFocusRing();

  return (
    <li
      {...mergeProps(optionProps, focusProps)}
      ref={ref}
      className={cx(
        'flex flex-row items-center rounded',
        isFocusVisible && 'ring-promote',
        'outline-none rounded-sm cursor-pointer my-2',
        isDisabled && 'opacity-50 cursor-not-allowed',
        isSelected && 'BU_is-active',
        isFocused && 'BU_is-focused',
      )}
    >
      <div className="px-3 py-2">{item.rendered}</div>
      <div className="px-3">
        {isSelected ? (
          <CheckIcon className="w-4 h-4" />
        ) : (
          <div className="w-4 h-4"></div>
        )}
      </div>
    </li>
  );
};
