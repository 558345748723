export const INVALID_GITHUB_TOKEN = 'INVALID_GITHUB_TOKEN';
export const GITHUB_API_ERROR = 'GITHUB_API_ERROR';
export const GITHUB_STORAGE_NOT_ALLOWED = 'GITHUB_STORAGE_NOT_ALLOWED';

export const INVALID_GITHUB_RESPONSE = 'INVALID_GITHUB_RESPONSE';
export const INVALID_GITHUB_FILE_FORMAT = 'INVALID_GITHUB_FILE_FORMAT';

export type ErrorCodesType =
  | typeof INVALID_GITHUB_TOKEN
  | typeof INVALID_GITHUB_RESPONSE
  | typeof INVALID_GITHUB_FILE_FORMAT
  | typeof GITHUB_API_ERROR
  | typeof GITHUB_STORAGE_NOT_ALLOWED;
