import React from 'react';

import { nsmApi2 } from '@bangle.io/api';
import { CorePalette } from '@bangle.io/constants';
import { vars } from '@bangle.io/css-vars';
import { safeRequestAnimationFrame } from '@bangle.io/utils';

import type { WorkspaceCreateErrorTypes } from './common';
import {
  CLICKED_TOO_SOON_ERROR,
  ERROR_PICKING_DIRECTORY_ERROR,
  INVALID_WORKSPACE_NAME_ERROR,
  UNKNOWN_ERROR,
  WORKSPACE_AUTH_REJECTED_ERROR,
  WORKSPACE_NAME_ALREADY_EXISTS_ERROR,
} from './common';

export function ShowError({
  errorType,
  closeModal,
}: {
  errorType: WorkspaceCreateErrorTypes | undefined;
  closeModal: () => void;
}) {
  if (!errorType) {
    return null;
  }

  let content, title;

  switch (errorType) {
    case WORKSPACE_NAME_ALREADY_EXISTS_ERROR: {
      title = 'Um espaço de trabalho com esse nome já existe.';
      content = (
        <div>
          <button
            className="underline"
            onClick={() => {
              closeModal();
              safeRequestAnimationFrame(() => {
                nsmApi2.ui.togglePalette(CorePalette.Workspace);
              });
            }}
          >
            Clique aqui
          </button>{' '}
          para acessá-lo.
        </div>
      );
      break;
    }

    case ERROR_PICKING_DIRECTORY_ERROR: {
      title = 'Ocorreu um erro ao abrir sua pasta de anotações.';
      content = (
        <div>
          Certifique-se de que sua pasta de anotações esteja em um local comum,
          como Documentos ou Área de Trabalho.
        </div>
      );
      break;
    }

    case CLICKED_TOO_SOON_ERROR: {
      title = 'Isso não funcionou';
      content = <div>Tente clicar no botão Escolher novamente.</div>;
      break;
    }
    case INVALID_WORKSPACE_NAME_ERROR: {
      title = 'Nome de espaço de trabalho inválido';
      content = (
        <div>
          Espaço de trabalho não podem possuir <code>:</code> em seu nome.
        </div>
      );
      break;
    }
    case WORKSPACE_AUTH_REJECTED_ERROR: {
      title = 'Vigogh Nota teve acesso cancelado ou negado a este destino.';
      content = (
        <div>
          Tente novamente e escolha <i>permitir</i> para que o Vigogh Nota tenha
          acesso a pasta.
        </div>
      );
      break;
    }
    case UNKNOWN_ERROR: {
      title = 'Um erro desconhecido aconteceu';
      content = <div>Recarregue a aba e tente novamente.</div>;
      break;
    }

    default: {
      // hack to catch switch slipping
      let val: never = errorType;
      throw new Error('Erro desconhecido: ' + val);
    }
  }

  return (
    <div
      className="w-full m-1 px-5 py-3 text-center rounded"
      data-testid={errorType}
      style={{
        backgroundColor: vars.color.critical.solidFaint,
        color: vars.color.critical.solidText,
      }}
    >
      <div className="font-semibold text-left">{title}</div>
      <div className="text-left">{content}</div>
    </div>
  );
}
